import { CButton } from '@coreui/react-pro';
import { EmployeeModal } from 'components/modals/modals';
import { useState } from 'hooks/hooks';
import React, { FC } from 'react';

type Props = {
  id: number,
  carServiceId: number,
  salaryDateFrom?: string,
  salaryDateTo?: string,
};

const IdCell: FC<Props> = ({ id, carServiceId, salaryDateFrom, salaryDateTo }) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isDisableModal, setIsDisableModal] = useState<boolean>(false);

  const onOpenViewModal = (): void => {
    setIsOpenModal(true);
    setIsDisableModal(true);
  };

  return (
    <td>
      <CButton
        color="link"
        size="sm"
        className="px-0"
        onClick={ onOpenViewModal }
      >
        { id }
      </CButton>
      {
        isOpenModal && (
          <EmployeeModal
            isOpen={ true }
            carServiceId={ carServiceId }
            employeeId={ id }
            isDisable={ isDisableModal }
            onEnable={ (): void => setIsDisableModal(false) }
            onClose={ (): void => setIsOpenModal(false) }
            salaryDateFrom={ salaryDateFrom }
            salaryDateTo={ salaryDateTo }
          />
        )
      }
    </td>
  );
};

export { IdCell };
