import CIcon from '@coreui/icons-react';
import cn from 'classnames';
import { FC } from 'react';

import { config, TypeContactField } from './config';
import styles from './style.module.scss';

type Props = {
  type: TypeContactField,
  value?: string | null,
};

const ContactField: FC<Props> = ({ type, value }) => {
  const { icon, className } = config[type];

  return (
    <div className={ cn(styles.contactFieldContainer, className) }>
      <span className={ styles.iconContainer }>
        <CIcon icon={ icon } className={ styles.icon } />
      </span>
      <span className={ cn(styles.value, { [styles.isEmpty]: !value }) }>
        { value }
      </span>
    </div>
  );
};

export { ContactField };
