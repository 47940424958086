import { CCol, CFormFeedback, CFormInput, CFormLabel, CRow } from '@coreui/react-pro';
import cn from 'classnames';
import { AppTranslationKey, EmployeesProfilesKey } from 'common/enums/enums';
import { EmployeeFormData } from 'common/types/types';
import { useAppSelector, useEffect, useFieldArray, useMemo } from 'hooks/hooks';
import { t as tt } from 'i18next';
import keyBy from 'lodash/keyBy';
import React, { FC } from 'react';
import { Control } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { UseFormRegister } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from '../../styles.module.scss';

enum TypeSalary {
  PERCENT_WORKS= 'PERCENT_WORKS',
  PERCENT_SPARES= 'PERCENT_SPARES',
  HOURLY_RATE= 'HOURLY_RATE',
  FIXED_RATE= 'FIXED_RATE',
}

const salaryConfig: Record<TypeSalary, { label: string, isPercent: boolean, step: number, max?: number }> = {
  'PERCENT_WORKS': {
    label: EmployeesProfilesKey.PERCENT_WORKS,
    isPercent: true,
    step: 0.0001,
    max: 100.0000,
  },
  'PERCENT_SPARES': {
    label: EmployeesProfilesKey.PERCENT_SPARES,
    isPercent: true,
    step: 0.0001,
    max: 100.0000,
  },
  'HOURLY_RATE': {
    label: EmployeesProfilesKey.HOURLY_RATE,
    isPercent: false,
    step: 1,
  },
  'FIXED_RATE': {
    label: EmployeesProfilesKey.FIXED_RATE,
    isPercent: false,
    step: 1,
  },
};

const numberValidate = (value: number, maxValue?: number): boolean | string => {
  if (!maxValue) {
    return true;
  }

  return value <= maxValue
    || tt(`${AppTranslationKey.COMMON}:inputNumberMaxValueWarning`, { maxValue: `${maxValue}%` }) as string;
};

type Props = {
  register: UseFormRegister<EmployeeFormData>,
  control: Control<EmployeeFormData>,
  errors: FieldErrors<EmployeeFormData>,
  isDisable: boolean,
};

const SalaryField: FC<Props> = ({ register, control, errors, isDisable }) => {
  const { t } = useTranslation(AppTranslationKey.EMPLOYEES_PROFILES);
  const { t: tCommon } = useTranslation(AppTranslationKey.COMMON);
  const salaryTypes = useAppSelector(({ dictionary }) => dictionary.data?.salaryTypes || []);
  const { fields, append } = useFieldArray({
    control,
    name: 'salaryTypes',
    rules: { required: true },
  });

  useEffect(() => {
    if (salaryTypes.length > 0 && fields.length < 4) {
      salaryTypes.forEach(({ salaryTypeId }) => {
        if (!fields.find((field) => field.salaryTypeId === salaryTypeId)) {
          append({ salaryTypeId, salaryTypeValue: 0 });
        }
      });
    }
  }, [salaryTypes, fields]);

  const mapSalaryTypes = useMemo(() => keyBy(salaryTypes, 'salaryTypeId'), [salaryTypes]);

  return (
    <CRow className="mb-3">
      <CFormLabel htmlFor="position" className="col-sm-3 col-form-label">
        { `${ t(`${ EmployeesProfilesKey.FIELDS }.${ EmployeesProfilesKey.METHOD_OF_SALARY }`) }*` }
      </CFormLabel>
      <CCol sm={9}>
        {
          fields.map((field, index) => {
            const salaryType = mapSalaryTypes[field.salaryTypeId];
            const label = salaryType.salaryTypeDescription;
            const { step, max, isPercent } = salaryConfig[salaryType.salaryTypeName as TypeSalary];
            const componentProps = register(
              `salaryTypes.${index}.salaryTypeValue`,
              { validate: (value) => numberValidate(value, max) },
            );

            return (
              <div key={field.salaryTypeId}>
                <div className={ cn(styles.salaryFieldItem, {
                  'is-invalid': Boolean(errors.salaryTypes?.[index]?.salaryTypeValue),
                }) }>
                  <div className={ styles.itemDescription }>
                    { label }
                  </div>
                  <CFormInput
                    { ...componentProps }
                    className={ styles.itemInput }
                    type="number"
                    min={ 0 }
                    step={ step }
                    invalid={Boolean(errors.salaryTypes?.[index]?.salaryTypeValue)}
                    disabled={ isDisable }
                  />
                  <span className={ styles.unit }>{ isPercent ? '%' : tCommon('uah') }</span>
                </div>
                <CFormFeedback invalid={ Boolean(errors.salaryTypes?.[index]?.salaryTypeValue) }>
                  { errors.salaryTypes?.[index]?.salaryTypeValue?.message || '' }
                </CFormFeedback>
              </div>
            );
          })
        }
      </CCol>
    </CRow>
  );
};

export { SalaryField };
