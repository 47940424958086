import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceDto } from 'common/types/types';
import { i18next } from 'i18n';

const ALL_CAR_SERVICE_ITEM: CarServiceDto = {
  id: -1,
  name: i18next.t(`${ AppTranslationKey.CAR_SERVICE }:allCarServiceText`),
  info: '',
  address:'',
  notCompletedRequests: 0,
  profilePhoto: '',
  contacts: null,
  requisites: null,
};

const getExtendedUserCarService = (carServices: CarServiceDto[]): CarServiceDto[] => {
  return carServices.length > 1 ? [ ALL_CAR_SERVICE_ITEM, ...carServices ] : carServices;
};

export { getExtendedUserCarService };
