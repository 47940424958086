import { cilMenu } from '@coreui/icons';
import CIcon from '@coreui/icons-react';
import {
  CContainer,
  CHeader,
  CHeaderToggler,
} from '@coreui/react-pro';
import cn from 'classnames';
import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs';
import { LangSelector } from 'components/common/common';
import { ExtraButton } from 'components/extra-button/extra-button';
import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openLeftMenu } from 'store/app/reducer';
import { IRootState } from 'store/root-reducer';

import { FeedbackButton } from './feedback-button';
import { HeaderDropdown } from './header-dropdown';
import styles from './styles.module.scss';
import { TelegramButton } from './telegram-button';

const Header: FC = () => {
  const dispatch = useDispatch();
  const isOpenLeftMenu = useSelector<IRootState, boolean>((state) => state.app.isOpenLeftMenu);
  const onToggle = ():void => {
    dispatch(openLeftMenu(!isOpenLeftMenu));
  };

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer className={ styles.containerHeaderMenu } fluid>
        <CHeaderToggler className={ cn(styles.itemMenu, 'ps-1') } onClick={onToggle}>
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <div className={styles.containerRightBlockMenu}>
          <TelegramButton/>
          <FeedbackButton/>
          <div className={styles.separateLine}/>
          <LangSelector/>
          <div className={styles.separateLine}/>
          <HeaderDropdown/>
        </div>
      </CContainer>
      <CContainer fluid className="mt-3">
        <Breadcrumbs />
        <ExtraButton/>
      </CContainer>
    </CHeader>
  );
};

export { Header };
