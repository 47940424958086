enum HttpStatusMessage {
  OK = 'OK',
  CREATED = 'Created',
  BAD_REQUEST = 'Bad Request',
  UNAUTHORIZED = 'Unauthorized',
  NOT_FOUND = 'Not Found',
  INTERNAL_SERVER_ERROR = 'Internal Server Error',
  NO_CONTENT = 'No Content',
  FORBIDDEN = 'Forbidden',
}

export { HttpStatusMessage };
