import { CCol, CFormCheck } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmFormDataFields } from '../enum/enum';
import { ConfirmFormDataRow } from '../type/type';
import styles from './styles.module.scss';

type Props = {
  label: ConfirmFormDataFields;
  value: ConfirmFormDataRow;
  onChangeClick: (rowName: ConfirmFormDataFields) => void;
};

const ConfirmFillFormRow: FC<Props> = ({ label, value, onChangeClick }) => {
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);

  return <div style={{ display: 'flex', marginBottom: 5 }}>
    <div className={styles.row}>
      <CCol className={styles.labelRow} xs={4}>
        <span>{t(label)}</span>
      </CCol>
      <CCol xs={8} className={styles.valueRow}>
        <span>{ value?.value ?? '...' }</span>
      </CCol>
    </div>
    <CFormCheck
      style={{ marginLeft: 3, visibility: (label === ConfirmFormDataFields.REG_NUM) ? 'hidden' : 'visible' }}
      checked={value?.checked ?? false}
      onChange={(): void => onChangeClick(label)}
    />
  </div>;
};

export { ConfirmFillFormRow };
