import { LocalStorageKey } from 'common/enums/enums';
import { CarServiceDto, TValue } from 'common/types/types';
import { useEffect, useState } from 'hooks/hooks';
import { storage } from 'services/services';

export interface IUseFilter<T> {
  initialValue: T,
  storageName: LocalStorageKey,
  activeCarService: CarServiceDto,
}

interface IUseFilterReturn {
  onChange: (name: string, value: TValue) => void,
  getValue: (name: string) => TValue,
  onSaveFilters: () => void,
  activeCarService: CarServiceDto,
}

const useFilter = <T extends Record<string, TValue>>({
  initialValue,
  storageName,
  activeCarService,
}: IUseFilter<T>): IUseFilterReturn => {
  const [filters, setFilters] = useState<T>(initialValue);

  const onChange = (name: string, value: TValue): void => {
    setFilters((prev) => ({ ...prev, [name]: value }));
  };

  const getValue = (name: string): TValue => filters[name];

  const onSaveFilters = (): void => {
    const savedFilters: Record<number, T> = JSON.parse(storage.getItem(storageName) ?? '{}');

    savedFilters[activeCarService.id] = filters;
    storage.setItem(storageName, JSON.stringify(savedFilters));
  };

  useEffect(() => {
    const savedFilters: Record<number, T> = JSON.parse(storage.getItem(storageName) ?? '{}');

    if (savedFilters[activeCarService.id]) {
      setFilters(savedFilters[activeCarService.id]);
    } else {
      setFilters(initialValue);
    }
  }, [storageName, activeCarService.id]);

  return { onChange, getValue, onSaveFilters, activeCarService };
};

export { useFilter };
