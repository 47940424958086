import {
  AllCarWorkModalTranslationKey,
  AppTranslationKey,
  AuthTranslationKey,
  CarWorkTranslationKey,
  ClientSparePartsTranslationKey,
  DangerAlertModalTranslationKey, EmployeesProfilesKey,
  ErrorPageTranslationKey,
  FeedBackModalKey,
  GdrpTranslationKey, HelpAboutRequestKey,
  InfoVinTranslationKey,
  NotFoundPageTranslationKey,
  OrderCertificateKey,
  OrderModalTranslationKey,
  RecoveryPasswordTranslationKey, SalaryKey, SelectComponentKey,
  SelectOrderModalTranslationKey,
  SelectSparePartsModalTranslationKey,
  SelectSparePartsTableTranslationKey,
  SelectWorkModalTranslationKey,
  SelectWorkToOrderModalTranslationKey,
  StorageDetailsTranslationKey,
  StorageTranslationKey,
  WorkOrderModalTranslationKey,
  WorkOrderTranslationKey,
} from 'common/enums/enums';

export const en = {
  breadcrumbs: {
    home: 'Home',
    dashboard: 'Dashboard',
    profile: 'Profile',
    carService: 'Car Service',
    edit: 'Edit',
    add: 'Add',
    myAutoServices: 'My Auto Services',
    carServicesProfiles: 'Service station profiles',
    carServicesCustomers: 'Customers',
    carServicesAuto: 'Cars',
    carServicesOrders: 'Orders',
    storage: 'Storage',
    suppliers: 'Suppliers',
    storagesProfiles: 'Storages profiles',
    work: 'Work',
    spareParts: 'Spare parts',
    workOrders: 'Work Orders',
    videoReview: 'Video Review',
    tariffs: 'Tariffs',
    services: 'Services',
    infoVin: 'Information about VIN',
    orderCertificate: 'Certificate of completion #{{orderId}}',
    help: 'Help',
    allAboutRequest: 'All about Request',
    employees: 'Employees',
    salary: 'Salary',
    employeesProfiles: 'Profiles of Employees',
  },
  mainMenu: {
    dashboard: 'Dashboard',
    myAutoServices: 'My Auto Services',
    ordersNavItem: 'Orders',
    carsNavItem: 'Cars',
    customersNavItem: 'Customers',
    carServicesNavItem: 'Service station profiles',
    profile: 'Profile',
    exit: 'Exit',
    storage: 'Storage',
    parts: 'Spare parts',
    work: 'Work',
    suppliers: 'Suppliers',
    storageProfiles: 'Storage Profiles',
    workOrders: 'Work Orders',
    videoReview: 'Video Review',
    tariffs: 'Tariffs',
    services: 'Services',
    infoVin: 'Information about VIN',
    help: 'Help',
    allAboutRequest: 'All about Request',
    employees: 'Employees',
    salary: 'Salary',
    employeesProfiles: 'Profiles of Employees',
  },
  [AppTranslationKey.AUTH]: {
    titleSignIn: 'Login',
    subtitleSignIn: 'Sign In to your account',
    secondSubtitleSignIn: 'Online platform for managing a car service',
    anyQuestion: 'Have questions or are you interested in additional information?',
    callMe: 'Call or write to us',
    crmTitle1: 'Take control of your service station:',
    crmTitle2: 'ERP System for efficient business management',
    crmTitle3: 'Isn\'t there enough time for STO development?',
    crmTitle4: 'ERP System is your solution!',
    crmOptions: 'Thanks to it you will be able to:',
    option1: 'Add requests from customers;',
    option2: 'Create Outfit-order;',
    option3: 'Keep track of spare parts in the warehouse;',
    option4: 'Get 30 days FREE unlimited after registration.',
    loginButtonText: 'Login',
    forgotPassword: 'Forgot password?',
    welcomeText: 'Welcome',
    dontHaveAccount: 'Don\'t have an account?',
    registerNowButtonText: 'Register Now!',
    loginErrorText: 'Please enter a valid email address',
    passwordErrorText: 'Password cannot be empty',
    register: 'Register',
    createAccount: 'Create your account',
    login: 'E-mail',
    phone: 'Phone',
    name: 'Full Name',
    password: 'Password',
    repeatPassword: 'Repeat password',
    createAccountButtonText: 'Create Account',
    backToSignIn: 'Back to Sign In',
    requiredPassword: 'Password is required',
    requiredName: 'Name is required',
    agreeToUsePersonalData: 'I agree to the processing of my personal data',
    agreeToUsePersonalDataError: 'Consent to data processing is required',
    machPasswordError: 'The passwords do not match',
    [AuthTranslationKey.REGISTRATION_MSG]: 'Congratulations! Your account has been created successfully. ' +
    'Confirm your e-mail to enter the system. Please check the email address you provided during registration. ' +
    'If you did not receive the email, please check your spam folder. If necessary, you can |' +
    ' If there are difficulties with Registration or Login, write' +
    ' to us | we provide an answer within 20 - 30 minutes.',
    [AuthTranslationKey.RESEND_ACTION_NAME]: 'resend email.',
    [AuthTranslationKey.INACTIVE_USER__MSG] :'Your account is currently inactive. To enter the system ' +
    'confirm your e-mail. We have already sent a letter to your email. If you did not receive the letter, ' +
    'check your spam folder. If necessary, you can | If there are difficulties with Registration or Login, write ' +
    'to us | we provide an answer within 20 - 30 minutes.',
    [AuthTranslationKey.ACTIVATED]: 'Your account is activated',
    [AuthTranslationKey.RESEND_VALIDATION_SUCCESS]: 'The letter has been sent',
  },
  [AppTranslationKey.CAR_SERVICE]: {
    allCarServiceText:'All service stations',
    backCaptionButton: 'Back',
    saveCaptionButton: 'Save',
    addCarServiceCaptionButton: 'Add service station',
    editCarServiceTitle: 'Edit service station',
    addCarServiceTitle: 'Adding service station',
    noCarServiceTitle: 'You don\'t have any service station :(',
    noCarServiceText: 'Click the Add Service station button to create it',
    carServiceFormFieldName: 'Name*',
    carServiceFormFieldStatus: 'Status*',
    carServiceFormFieldNameErrorRequired: 'This field is required',
    carServiceFormFieldAddress: 'Address*',
    carServiceFormFieldAddressErrorRequired: 'This field is required',
    carServiceFormFieldInfo: 'Description',
    carServiceFormFieldInfoText: 'There are {{countSymbols}} characters left',
    carServiceFormFieldInfoErrorMaxLength: 'Too many characters',
    errorMessageNoCarService: 'First, it is necessary to create a service station',
    carServiceUpdate: 'Service station successfully updated',
    carServiceAdd: 'Service station successfully added',
    infoCardTitle: 'Information',
    logo: 'Logo',
    addLogoButton: 'Add a file',
    removeLogoButton: 'Remove',
    fileOversizeError: 'The file size must be less than 25MB',
    contactsCardTitle: 'Contacts',
    mainPhone: 'Phone (Main)',
    phone: 'Phone (Optional)',
    viber: 'Viber',
    telegram: 'Telegram',
    email: 'E-mail',
    detailsCardTitle: 'Details',
    recipient: 'Name of the recipient',
    ipn: 'TIN',
    legalAddress: 'Legal addresses',
    edrpou: 'EDRPOU',
    account: 'Current account (р/р, IBAN)',
    mfo: 'MFI',
    bank: 'Bank',
  },
  carServiceDetails: {
    carServiceDetailsName: 'Name:',
    carServiceDetailsStatus: 'Status:',
    carServiceDetailsAddress: 'Address:',
    carServiceDetailsInfo: 'Description:',
    carServiceDetailsEditCaptionButton: 'Edit',
    carServiceDetailsSaveCaptionButton: 'Save',
  },
  [AppTranslationKey.CAR_SERVICE_ORDER]: {
    title: 'Requests',
    orderAccordionHeader: 'Repair requests',
    profileAccordionHeader: 'Service station profile',
    autoAccordionHeader: 'Cars',
    customerAccordionHeader: 'Customers',
    addOrderCaptionButton: 'Add Order',
    showDetailsCaption: 'Show',
    hideDetailsCaption: 'Hide',
    orderTableColumns: '#|Auto|Date / Time|Status|Comments|Sum|Sum with orders|Sum without orders',
    orderProblemTitle: 'Work',
    orderSparePartsTitle: 'Spare parts',
    orderClientSparePartsTitle: 'Spare parts from the customer',
    orderCommentTitle: 'Comment',
    orderPerformer: 'Performer',
    orderViewButton: 'View',
    orderEditButton: 'Edit',
    order: 'Repair request',
    orderAuto: 'Auto',
    noOrdersTitle: 'You have no order',
    noOrdersText: 'Click the "Add Order" button to create it',
    itemsPerPageLabel: 'Items per page',
    successEditOrderMes: 'Order updated successfully',
    openWorkErrorMes: 'It is not possible to close an application that has uncompleted work.',
    successAddOrderMes: 'Order successfully added',
    defPerformer: 'Without performer',
    filters: {
      today: 'Today',
      currentWeek: 'Current week',
      currentMonth: 'Current month',
      startDay: 'Start date',
      endDay: 'End date',
      status: 'Status...',
      apply: 'Apply',
      selectAllOptions: 'Select all options',
    },
    orderNoWork: 'There are no works in the order',
    orderNoSpareParts: 'There are no spare parts in the order',
    orderNoUserSpareParts: 'The order does not have spare parts from the customer',
  },
  shortDayOfWeek: {
    sun: 'Sun',
    mon: 'Mon',
    tues: 'Tues',
    wed: 'Wed',
    thurs: 'Thurs',
    fri: 'Fri',
    sat: 'Sat',
  },
  [AppTranslationKey.ORDER_MODAL]: {
    modalTitle: 'Order',
    autoFieldLabel: 'Car*',
    requiredFormField: 'This is a required field',
    dateFieldLabel: 'Scheduled Date*',
    timeFieldLabel: 'Scheduled Time*',
    performerFieldLabel: 'Performer',
    shallDoFieldLabel: 'Works',
    spareParts: 'Spare parts',
    userSpareParts: 'Spare parts from the customer',
    duties: 'Duties',
    statusFieldLabel: 'Status',
    commentsFieldLabel: 'Comments',
    editButtonCation: 'Edit',
    saveButtonCaption: 'Save',
    closeButtonCaption: 'Close',
    newBtn: 'New',
    selectBtn: 'Select',
    selectCar: 'Choose a car ...',
    noSelectCar: 'Car no selected',
    workName: 'Job title',
    numberTourOfDuty: 'Tour of duty',
    performer: 'Performer',
    status: 'Status',
    [OrderModalTranslationKey.ORDER_NO_WORK]: 'The order has no works',
    [OrderModalTranslationKey.ADD_NEW_WORK_ORDER]: 'Add work order',
    [OrderModalTranslationKey.TOTAL_SUM_ORDER]: 'Order sum',
    [OrderModalTranslationKey.TOTAL_SUM_WORKS_OF_ORDER]: 'Work Order sum',
    [OrderModalTranslationKey.TOTAL_SUM_SPARE_PARTS_OF_ORDER]: 'Spare parts sum',
  },
  [AppTranslationKey.CAR_MODAL]: {
    modalTitle: 'Car',
    vin: 'VIN',
    brand: 'Brand',
    model: 'Model',
    bodyType: 'Body type',
    color: 'Color',
    regNum: 'Registration number',
    createYear: 'Year of issue',
    mileageKm: 'Mileage (km)',
    carInfo: 'Additional Information',
    saveButtonCaption: 'Save',
    selectBrand: 'Choose a brand ...',
    selectModel: 'Choose a model ...',
    selectBodyType: 'Choose a body type ...',
    selectColor: 'Choose a color ...',
    successCreateCarMes: 'Car added successfully',
    successModifyCarMes: 'Car updated successfully',
    requiredFormField: 'This is a required field',
    closeButtonCaption: 'Close',
    editButtonCation: 'Edit',
    modalListTitle: 'Cars',
    addNewCarBtn: 'Add new car',
    selectBtn: 'Select',
    findBtnText: 'Search',
    searchBtnErrorText: 'Enter the registration number and repeat the search',
    table: {
      regNum: 'Reg. number',
      brand: 'Brand',
      model: 'Model',
      info: 'Add. Info',
    },
    selectCarText: 'Selected: [{{ car }}]',
    noSelectCarText: 'No selected car',
    capacity: 'Engine volume (cm³)',
    fillFormModalHeader: 'Update vehicle data',
    fillFormModalFooter: 'Select the data you want to update or add. ' +
      'By default, all fields are selected, but you can leave only those that need to be changed.',
    fillFormModalBody: 'The vehicle information you requested was successfully found: ',
    fillFormBtnClose: 'Close',
    fillFormBtnConfirm: 'Fill the blanks',
    fillFormBtnConfirmAll: 'Change',
    errorSelectBrandMessage: 'First, you need to choose a car brand',
  },
  [AppTranslationKey.PROFILE]: {
    title: 'Profile',
    login: 'Login:',
    name: 'Name:',
    dateProfile: 'Registration Date:',
    tariffName: 'Current tariff:',
    tariffStatus: 'Status:',
    tariffStart: 'Connection date:',
    tariffEnd: 'End date:',
    changePassword: 'Change password',
    selectTariff: 'Choose a tariff',
    noData: 'No data',
    activeStatus: 'Active',
    paidStatus: 'Paid',
    endStatus: 'It\'s over',
    requiredName: 'You must enter a name',
    phone: 'Phone:',
    errorPhone: 'Invalid phone number',
    changePasswordButton: 'Change password',
    changePasswordModal: {
      title: 'Changing the authorization password',
      saveButton: 'Change',
      closeButton: 'Close',
      oldPasswordTitle: 'Enter old password',
      newPasswordTitle: 'Enter new password',
      repeatNewPasswordTitle: 'Repeat password',
      passwordPlaceholder: 'Password',
      passwordErrorText: 'Password cannot be empty',
      matchNewPasswordError: 'The password matches the current one',
      matchPasswordError: 'Passwords do not match',
      successMessage: 'The password has been changed',
    },
  },
  [AppTranslationKey.CAR_SERVICE_AUTO]: {
    title: 'Cars',
    addCarCaptionButton: 'Add car',
    noCarsTitle: 'You have no cars',
    noCarsText: 'Click the Add Car button to create one',
    carTableColumns: '#|Car number|Brand|Model|Color|VIN',
    showDetailsCaption: 'Show',
    hideDetailsCaption: 'Hide',
    carBodyTypeName: 'Body Type',
    createYear: 'Year of release',
    mileageKm: 'Mileage, km',
    carInfo: 'Info',
    itemsPerPageLabel: 'Items Per Page',
    vinCode: 'vin-code',
    carViewButton: 'View',
    carEditButton: 'Edit',
    capacity: 'Engine volume (cm³)',
  },
  [AppTranslationKey.CAR_SERVICE_CUSTOMER]: {
    addCustomerCaptionButton: 'Add Customer',
    noCustomersTitle: 'You have no customers',
    noCustomersText: 'Click the Add Customer button to create one',
  },
  widgets: {
    title: 'Desktop and analytics',
    countAllTask: 'Total number of requests',
    countOpenTask: 'The number of open applications',
    countAllCars: 'Total number of cars',
  },
  [AppTranslationKey.RECOVERY_PASSWORD]: {
    [RecoveryPasswordTranslationKey.TITLE_RECOVERY_PASSWORD]: 'Password recovery',
    [RecoveryPasswordTranslationKey.SUBTITLE_RECOVERY_PASSWORD]: 'Enter your e-mail address',
    [RecoveryPasswordTranslationKey.PLACEHOLDER_RECOVERY_PASSWORD]: 'E-mail',
    [RecoveryPasswordTranslationKey.EMAIL_ERROR_TEXT]: 'Please enter a valid e-mail',
    [RecoveryPasswordTranslationKey.RECOVERY_PASS_BUTTON_TEXT]: 'Recover',
    [RecoveryPasswordTranslationKey.WELCOME_TEXT]: 'Verify account',
    [RecoveryPasswordTranslationKey.BACK_BUTTON_TEXT]: 'Back',
    [RecoveryPasswordTranslationKey.RECOVERY_PASS_TEXT]:
      'Enter the Email you used upon registration, we will send you a link to change your password',
    [RecoveryPasswordTranslationKey.CHANGE_PASS_TEXT]: 'Create a new password for the account',
    [RecoveryPasswordTranslationKey.CONFIRM_MSG]:
      `A password reset link has been sent. Check your mailbox.
      If you did not receive the email, please check your spam folder.`,
    [RecoveryPasswordTranslationKey.BACK_TO_SIGN_IN]: 'Back to SignIn',
    [RecoveryPasswordTranslationKey.REQUEST_ERROR_MSG]: 'The request was not sent',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_TITLE_TEXT]: 'Change authorization password',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_SUBTITLE_TEXT]: 'Enter a new password',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_PLACEHOLDER]: 'Password',
    [RecoveryPasswordTranslationKey.CHANGE_PASSWORD_BUTTON_TEXT]: 'Change',
    [RecoveryPasswordTranslationKey.NEW_PASSWORD_ERROR_TEXT]: 'Password cannot be empty',
    [RecoveryPasswordTranslationKey.ANY_DIFFICULTY]: 'Did you have any difficulties?',
    [RecoveryPasswordTranslationKey.ANY_QUESTION]: 'Questions arose?',
    [RecoveryPasswordTranslationKey.CALL_ME]: 'Call or write to us',
  },
  [AppTranslationKey.SUPPLIERS]: {
    backCaptionButton: 'Back',
    saveCaptionButton: 'Save',
    noDataTitle: 'You have no suppliers :(',
    noDataText: 'Click the "Add supplier" button to create a new one',
    addButtonTitle: 'Add supplier',
    addSupplierTitle: 'Adding a supplier',
    editSupplierTitle: 'Editing a supplier',
    fieldInfoLabel: 'Description of the supplier',
    fieldStatusLabel: 'Status of the supplier',
    fieldAddressLabel: 'Address of the supplier',
    fieldNameLabel: 'Name of the supplier',
    selectStatus: 'Select status ...',
    createSupplierSuccessMessage: 'Supplier successfully created!',
    editSupplierSuccessMessage: 'Supplier successfully edited!',
    tableColumns: '#|Name|Status|Address',
    requiredFormField: 'This is a required field',
    itemsPerPageLabel: 'Items per page',
  },
  [AppTranslationKey.COMMON]: {
    maxPhoneLengthError: 'The maximum number of characters - 15',
    feedbackBtnTitle: 'Support',
    tgLogoTip: 'Telegram support',
    feedback: 'Email support',
    textAreaText: 'There are {{countSymbols}} characters left',
    textAreaErrorMaxLength: 'Too many characters',
    showDetailsCaption: 'Show',
    hideDetailsCaption: 'Hide',
    viewButton: 'View',
    editButton: 'Edit',
    selectBtn: 'Select',
    saveBtn: 'Save',
    closeBtn: 'Close',
    cancelBtn: 'Cancel',
    removeBtn: 'Delete',
    permissionMessage: 'To continue using all system features, you should connect a tariff plan',
    itemPerPageLabel: 'Items per page',
    uah: 'uah',
    noItemsFoundTitle: 'No items found',
    noItemsFoundMsg: 'Try changing your search terms',
    docButtonCaption: 'Documentation',
    inputNumberMaxValueWarning: 'The value cannot exceed {{maxValue}}',
  },
  [AppTranslationKey.STOCK]: {
    [StorageTranslationKey.ADD_STORAGE]: 'Add Storage',
    [StorageTranslationKey.NO_DATA_TITLE]: 'You do not have any storage :(',
    [StorageTranslationKey.NO_DATA_TEXT]: 'Click the "Add Storage" button to create it',
    [StorageTranslationKey.ADD_STORAGE_TITLE]: 'Adding a storage',
    [StorageTranslationKey.EDIT_STORAGE_TITLE]: 'Storage editing',
    [StorageTranslationKey.STORAGE_FORM_FIELD_REQUIRED]: 'This field is required',
    [StorageTranslationKey.STORAGE_FORM_NAME_LABEL]: 'Name*',
    [StorageTranslationKey.STORAGE_FORM_STATUS_LABEL]: 'Status',
    [StorageTranslationKey.STORAGE_FORM_ADDRESS_LABEL]: 'Address*',
    [StorageTranslationKey.STORAGE_FORM_TOO_MANY_CHAR]: 'Too many characters',
    [StorageTranslationKey.STORAGE_FORM_INFO_LABEL]: 'Info',
    [StorageTranslationKey.STORAGE_FORM_INFO_TEXT]: 'There are {{countSymbols}} characters left',
    [StorageTranslationKey.BACK_CAPTION_BUTTON]: 'Back',
    [StorageTranslationKey.SAVE_CAPTION_BUTTON]: 'Save',
    [StorageTranslationKey.ADD_STORAGE_SUCCESS]: 'Storage successfully added!',
    [StorageTranslationKey.UPDATE_STORAGE_SUCCESS]: 'Storage successfully updated!',
  },
  [AppTranslationKey.STOCK_DETAILS]: {
    [StorageDetailsTranslationKey.STORAGE_DETAILS_NAME_FIELD]: 'Name',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_STATUS_FIELD]: 'Status',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_ADDRESS_FIELD]: 'Address',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_INFO_FIELD]: 'Info',
    [StorageDetailsTranslationKey.STORAGE_DETAILS_EDIT_BUTTON]: 'Edit',
  },
  [AppTranslationKey.CAR_WORK]: {
    [CarWorkTranslationKey.ADD_CAR_WORK_CAPTION_BUTTON]: 'Edit selected works',
    [CarWorkTranslationKey.NO_CAR_WORK_TITLE]: 'You do not have any car work',
    [CarWorkTranslationKey.NO_CAR_WORK_TEXT]:
      'Click the "Edit selected works" button to add/remove a car work from the general car work directory',
    [CarWorkTranslationKey.CAR_WORK_TABLE_COLUMNS]:
      'Name of work|Type of work|Time, min|Cost|Information',
    [CarWorkTranslationKey.FAV_CAR_WORK_NAME]: 'Work name:',
    [CarWorkTranslationKey.UPDATE_SUCCESS]: 'Data updated successfully',
    [CarWorkTranslationKey.FAV_CAR_WORK_TYPE]: 'Work type:',
    [CarWorkTranslationKey.FAV_CAR_WORK_TIME]: 'Time in minutes:',
    [CarWorkTranslationKey.FAV_CAR_WORK_COST]: 'Cost:',
    [CarWorkTranslationKey.FAV_CAR_WORK_INFO]: 'Info:',
    [CarWorkTranslationKey.VALUE_LESS_ZERO]: 'The value cannot be less than 0',
    [CarWorkTranslationKey.INVALID_NUMBER]: 'invalid value',
    [CarWorkTranslationKey.ITEMS_PER_PAGE_LABEL]: 'Items per page',
    [CarWorkTranslationKey.EDIT_CAR_WORK_MODAL_TITLE]: 'Editing work',
    [CarWorkTranslationKey.ALL_CAR_WORK_MODAL]: {
      [AllCarWorkModalTranslationKey.ALL_CAR_WORK_MODAL_TITLE]: 'Directory of service works',
      [AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_NAME]: 'Title of work',
      [AllCarWorkModalTranslationKey.CAR_WORK_MODAL_WORK_TYPE]: 'Type of work',
      [AllCarWorkModalTranslationKey.CAR_WORK_NUM_MODAL]: 'Car work',
      [AllCarWorkModalTranslationKey.CAR_WORK_PRICE_MODAL]: 'Price',
      [AllCarWorkModalTranslationKey.CAR_WORK_QTY_MODAL]: 'Qty',
      [AllCarWorkModalTranslationKey.CAR_WORK_SUM_MODAL]: 'Sum',
      [AllCarWorkModalTranslationKey.FEEDBACK_BUTTON_TITLE]: 'Didn\'t find the right job?',
    },
    [CarWorkTranslationKey.SELECT_WORK_TO_ORDER_MODAL]: {
      [SelectWorkToOrderModalTranslationKey.TITLE]: 'Works on order #',
      [SelectWorkToOrderModalTranslationKey.SELECTED]: 'Selected',
      [SelectWorkToOrderModalTranslationKey.SUM]: 'Work Order sum',
    },
  },
  [AppTranslationKey.SPARE_PARTS]: {
    addButtonTitle: 'Add spare part',
    createSuccessMessage: 'Spare part successfully created!',
    editSuccessMessage: 'Spare part successfully edited!',
    createModalTitle: 'New spare part',
    editModalTitle: 'Edit spare part',
    name: 'Name*',
    sparePartName: 'Spare part name',
    orderId: 'Work order',
    priceOne: 'Price',
    count:'Count',
    priceTotal:'Amount',
    addInfo: 'Additional info',
    supplier: 'Supplier*',
    valueCurrent: 'Quantity in stock*',
    priceSell: 'Selling price*',
    valueMin: 'Minimum quantity*',
    originalCode: 'Original code',
    requiredFormField: 'This is a required field',
    selectSupplier: 'Select supplier ...',
    noDataTitle: 'You do not have any spare part :(',
    noDataText: 'Click the "Add spare part" button to create it',
    tableColumns: '#|Name|Quantity|Min quantity|Price|Code',
    itemPerPageLabel: 'Items per page',
    noStoreErrorMes: 'First you need to create a warehouse',
  },
  [AppTranslationKey.GDRP_MODAL]: {
    [GdrpTranslationKey.TITLE]: 'Privacy policy and user data processing',
    [GdrpTranslationKey.CLOSE_BUTTON_CAPTION]: 'Close',
  },
  [AppTranslationKey.WORK_ORDER]: {
    [WorkOrderTranslationKey.TITLE]: 'Orders',
    [WorkOrderTranslationKey.SELECTED_ORDER_MSG] : '#{{orderId}} from {{date}} {{carInfo}}',
    [WorkOrderTranslationKey.CREATE_CANCELED_WORK_ORDER_ERROR_TEXT]:
      'Unable to create an outfit with "Cancelled" status',
    [WorkOrderTranslationKey.EDIT_CANCELED_WORK_ORDER_ERROR_TEXT]: 'It is not possible to edit a canceled order. ' +
    'Create a new order',
    [WorkOrderTranslationKey.ADD_WORK_ORDER]: 'Add work order',
    [WorkOrderTranslationKey.NO_WORK_ORDERS_TITLE]: 'You do not have any work orders',
    [WorkOrderTranslationKey.WORK_ORDER_DETAILS_WORK_TITLE]: 'Work',
    [WorkOrderTranslationKey.WORK_ORDER_DETAILS_SPARE_PARTS_TITLE]: 'Spare parts',
    [WorkOrderTranslationKey.WORK_ORDER_NO_WORK]: 'The work order has no works',
    [WorkOrderTranslationKey.WORK_ORDER_NO_SPARE_PARTS]: 'The work order has no spare parts',
    [WorkOrderTranslationKey.NO_WORK_ORDERS_TEXT]:
      'Click the "Add work order", to create it',
    [WorkOrderTranslationKey.NUM_ORDER]: 'Order',
    [WorkOrderTranslationKey.DATE_TIME]: 'Data / Time',
    [WorkOrderTranslationKey.AUTO]: 'Auto',
    [WorkOrderTranslationKey.COMMENT]: 'Comment',
    [WorkOrderTranslationKey.SUM_FULL]: 'Sum',
    [WorkOrderTranslationKey.SUM_WORKS]: 'Works sum',
    [WorkOrderTranslationKey.SUM_SPARE_PARTS]: 'Spare parts sum',
    [WorkOrderTranslationKey.STATUS]: 'Status',
    [WorkOrderTranslationKey.ADD_SUCCESS]: 'Added work order',
    [WorkOrderTranslationKey.EDIT_SUCCESS]: 'Edited work order',
    [WorkOrderTranslationKey.TOTAL_WORK_ORDER_SUM]: 'Work order sum',
    [WorkOrderTranslationKey.WORK_ORDER_MODAL]: {
      [WorkOrderModalTranslationKey.TITLE]: 'Work order',
      [WorkOrderModalTranslationKey.STATUS_LABEL]: 'Status',
      [WorkOrderModalTranslationKey.ORDER]: 'Order*',
      [WorkOrderModalTranslationKey.DATE]: 'Date',
      [WorkOrderModalTranslationKey.TIME]: 'Time',
      [WorkOrderModalTranslationKey.NO_DATA_MSG]: 'No Data',
      [WorkOrderModalTranslationKey.SELECT_BTN_CAPTION]: 'Select',
      [WorkOrderModalTranslationKey.REQUIRED]: 'This field is required',
      [WorkOrderModalTranslationKey.WORK_NAME_TABLE_FIELD]: 'Work name',
      [WorkOrderModalTranslationKey.SPARE_PART_NAME_TABLE_FIELD]: 'Spare part name',
      [WorkOrderModalTranslationKey.QTY_TABLE_FIELD]: 'Quantity',
      [WorkOrderModalTranslationKey.COST_TABLE_FIELD]: 'Cost',
      [WorkOrderModalTranslationKey.SUM_TABLE_FIELD]: 'Sum',
      [WorkOrderModalTranslationKey.NO_BIND_ORDER_ERROR_MSG]: 'You must select an order',
      [WorkOrderModalTranslationKey.EMPLOYEE]: 'Performer',
    },
  },
  [AppTranslationKey.TARIFFS] :{
    title: 'View tariffs',
    testTariffName: 'Unlimited trial',
    minTariffName: 'Minimum',
    standardTariffName: 'Standard',
    unlimitedTariffName: 'Unlimited',
    premiumTariffName: 'Premium',
    countRequests: 'Number of open applications',
    works: 'Works',
    workOutfit: 'Work outfit order',
    provider: 'Suppliers',
    storage: 'Storage',
    calculateCost: 'Calculate the cost of the tariff',
    period: 'Subscription period',
    month: 'month',
    subscribeButton: 'Subscribe',
    calculatePrice: 'Calculate the cost',
    periodSubscribers: 'Subscription period {{countMonth}} / month',
    descriptionPayment: 'Use UkrCarService. Tariff: {{tariffName}} - {{countMonth}} month',
    currencyUAH: 'UAH',
    disableButtonText: 'Not available',
    activePanelText: 'Active until {{endDate}}',
    planedPanelText: 'Scheduled from {{startDate}}',
    finishedPanelText: 'Finished {{endDate}}',
    calcPrice: 'Calculate the cost',
    addInfo: {
      partnership: {
        info1: 'The validity period is unlimited, subject to the conditions:',
        info2: 'active use of the ERP-system',
        info3: 'providing feedback and suggestions regarding functionality',
      },
      testUnlimited: {
        info1: 'Subscription period - 1 month',
        info2: 'It is activated when the first service station is created',
        info3: 'It is activated when the first storage is created',
      },
    },
  },
  [AppTranslationKey.HELP] :{
    title: 'Video overview of the system',
    video_title: 'Video overview at the current moment',
  },
  [AppTranslationKey.SELECT_ORDER_MODAL]: {
    [SelectOrderModalTranslationKey.TITLE] : 'Orders',
    [SelectOrderModalTranslationKey.NO_ORDER_SELECTED] : 'No order selected',
    [SelectOrderModalTranslationKey.AUTO_TABLE_FIELD] : 'Auto',
    [SelectOrderModalTranslationKey.DATETIME_TABLE_FIELD] : 'Date / Time',
    [SelectOrderModalTranslationKey.COMMENT_TABLE_FIELD] : 'Comment',
    [SelectOrderModalTranslationKey.STATUS_TABLE_FIELD] : 'Status',
    [SelectOrderModalTranslationKey.ALERT_ORDER_TITLE] : 'Order:',
    [SelectOrderModalTranslationKey.ALERT_AUTO_TITLE] : 'Auto:',
    [SelectOrderModalTranslationKey.SELECTED_ORDER_MSG] : '#{{orderId}} from {{date}}',
  },
  [AppTranslationKey.SELECT_WORK_MODAL]: {
    [SelectWorkModalTranslationKey.NO_SELECT]: 'There are no selected works',
    [SelectWorkModalTranslationKey.SELECTED]: 'Selected',
    [SelectWorkModalTranslationKey.SELECT_WORK_TITLE]: 'Select work',
    [SelectWorkModalTranslationKey.SELECT_WORK_SUM]: 'Work order sum',
  },
  [AppTranslationKey.SELECT_SPARE_PARTS_MODAL]: {
    [SelectSparePartsModalTranslationKey.TITLE]: 'Spare parts',
    [SelectSparePartsModalTranslationKey.SELECTED]: 'Selected',
    [SelectSparePartsModalTranslationKey.NO_SELECT]: 'There are no selected spare parts',
  },
  [AppTranslationKey.SELECT_SPARE_PARTS_TABLE]: {
    [SelectSparePartsTableTranslationKey.NO_SPARE_PARTS_WORK_TITLE]: 'You do not have any spare parts',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_NAME]: 'Name',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_STOCK_NAME]: 'Stock',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_QTY]: 'Quantity',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_PRICE]: 'Cost',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_SUM]: 'Sum',
    [SelectSparePartsTableTranslationKey.SPARE_PARTS_COUNTERPARTY_NAME]: 'Provider',
    [SelectSparePartsTableTranslationKey.TOTAL_SPARE_PARTS_SUM]: 'Total sum',
  },
  [AppTranslationKey.DANGER_ALERT_MODAL]: {
    [DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_TEXT]:
      'Are you sure about rejecting the outfit? All spare parts will be returned to stock.',
    [DangerAlertModalTranslationKey.CANCEL_BUTTON_TEXT]: 'Cancel',
    [DangerAlertModalTranslationKey.CANCEL_WORK_ORDER_BUTTON_TEXT]: 'Reject the work order',
    [DangerAlertModalTranslationKey.DELETE_PHONE_TEXT]: 'Are you sure you want to delete the phone?',
    [DangerAlertModalTranslationKey.DELETE_PHONE_BUTTON_TEXT]: 'Delete phone',
    [DangerAlertModalTranslationKey.CANCEL_ORDER_TEXT]:
      'Are you sure about rejecting the order? All outfits will be canceled and parts will be returned to stock.',
    [DangerAlertModalTranslationKey.CANCEL_ORDER_BUTTON_TEXT]: 'Reject the order',
  },
  [AppTranslationKey.INFO_VIN]: {
    [InfoVinTranslationKey.TITLE]: 'View information by VIN',
    [InfoVinTranslationKey.SEARCH_BUTTON_TEXT]: 'Search',
    [InfoVinTranslationKey.SEARCH_INPUT_TITLE]: 'Search for information about the car',
    [InfoVinTranslationKey.VIN_INPUT_PLACEHOLDER]: 'Enter VIN',
    [InfoVinTranslationKey.GOS_NUMBER_INPUT_PLACEHOLDER]: 'Enter your state number',
    [InfoVinTranslationKey.GOS_NUMBER_INPUT_ACTIVE_PLACEHOLDER]: 'АА0000АА',
    [InfoVinTranslationKey.ERROR_SHORT_VIN]: 'VIN is too short. It must contain 17 characters',
    [InfoVinTranslationKey.ERROR_LONG_VIN]: 'VIN is too long. It must contain 17 characters',
    [InfoVinTranslationKey.ERROR_REG_NUM]: 'State number must be valid',
    [InfoVinTranslationKey.ERROR_EMPTY_SEARCH]: 'It is necessary to fill in at least one of the fields: ' +
      'Vin-code or state number',
    [InfoVinTranslationKey.TABLE_INFO_TITLE]: 'Information about the car',
    [InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TITLE]: 'Get information about a car by VIN code or license plate' +
      ' easily and quickly!',
    [InfoVinTranslationKey.SEARCH_INFO_MESSAGE_TEXT]: '<p>Enter your vehicle\'s VIN or State Number ' +
      'in the appropriate vehicles registered or re-registered after 2019. The search by license plate ' +
      'includes a much larger volume of cars</p><p><bold>How to correctly enter the VIN code and ' +
      'state number in the search field?</bold> Our search system ensures ease of data entry and speed ' +
      'of processing your request.</p><ul><li>VIN code and State number can be entered in both uppercase and ' +
      'lowercase characters, with or without spaces. If the data contains a dash “-”, it must be entered.</li>' +
      '<li>Controversial letters, such as "i", "a", "o", "B", "K", "e", and others, can be entered in ' +
      'both Cyrillic and Latin letters.</li></ul>',
    [InfoVinTranslationKey.TABLE_FIELD_CAR]: 'Car',
    [InfoVinTranslationKey.TABLE_FIELD_VIN]: 'VIN',
    [InfoVinTranslationKey.TABLE_FIELD_YEAR]: 'Year of release',
    [InfoVinTranslationKey.TABLE_FIELD_COLOR]: 'Color',
    [InfoVinTranslationKey.TABLE_FIELD_BODY]: 'Body type',
    [InfoVinTranslationKey.TABLE_FIELD_FUEL]: 'Fuel type',
    [InfoVinTranslationKey.TABLE_FIELD_CAPACITY]: 'Engine capacity',
    [InfoVinTranslationKey.TABLE_FIELD_OWN_WEIGHT]: 'Own weight',
    [InfoVinTranslationKey.TABLE_FIELD_TOTAL_WEIGHT]: 'Total weight',
    [InfoVinTranslationKey.TABLE_FIELD_REG_NUM]: 'State number',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_TITLE]: 'What is a VIN code?',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_TEXT]: '<0>VIN</0> (Vehicle Identification Number), also known as ' +
      '"body number" - is a unique combination of characters that identifies a car. ' +
      'This identification number is assigned by the manufacturer to identify each individual vehicle.',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TITLE]: 'Where is the VIN code?',
    [InfoVinTranslationKey.INFO_BLOCK_VIN_CAR_TEXT]: 'In addition to the technical license, ' +
      'the VIN code of the car can be found on the identification plate, which is usually located in ' +
      'a special window near the windshield on the driver\'s side, on the front left door pillar, under ' +
      'the floor covering next to the seats in front, or under the hood or wheel arches.',
  },
  [AppTranslationKey.ERROR_PAGE]: {
    [ErrorPageTranslationKey.TITLE]: 'Something went wrong...',
    [ErrorPageTranslationKey.BACK_TO_MAIN]: 'To Main page',
    [ErrorPageTranslationKey.CONTACT_US_TEXT]: 'If the error persists, please ',
    [ErrorPageTranslationKey.CONTACT_US_LINK_TEXT]: 'contact our support',
    [ErrorPageTranslationKey.MSG]:
    '<p>Sorry, an error occurred while processing your request. ' +
    'We are already working on a solution to this problem. Please try again later.</p>' +
    '<p><bold>Recommended actions:</bold></p>' +
    '<ul><li>Reload the page and try again.</li>' +
    '<li> Check your Internet connection and try again.</li>' +
    '</ul>' +
    '<p><bold>Additional help:</bold></p>' +
    '<ul>' +
    '<li>If the problem persists, ' +
    '<contactUs>ontact our support</contactUs> ' +
    'or call our hotline 0 800 332 308.</li>' +
    '</ul>',
  },
  [AppTranslationKey.NOT_FOUND_PAGE]: {
    [NotFoundPageTranslationKey.TITLE]: 'Oops! You\'re lost.',
    [NotFoundPageTranslationKey.MSG]:'The page you are looking for was not found.',
  },
  [AppTranslationKey.ORDER_CERTIFICATE]: {
    [OrderCertificateKey.PAGE_TITLE]: 'Review of the act of completed works',
    [OrderCertificateKey.TITLE]: 'Act of completed works {{num}}',
    [OrderCertificateKey.PERFORMER_SIDE_TITLE]: 'Performer',
    [OrderCertificateKey.CUSTOMER_SIDE_TITLE]: 'Customer',
    [OrderCertificateKey.NAME_SERVICE_STATION]: 'Name service station',
    [OrderCertificateKey.ADDRESS_SERVICE_STATION]: 'Address',
    [OrderCertificateKey.PHONE_SERVICE_STATION]: 'Phone',
    [OrderCertificateKey.BRAND_CAR]: 'Brand',
    [OrderCertificateKey.MODEL_CAR]: 'Model',
    [OrderCertificateKey.GOV_NUMBER_CAR]: 'Govt. number',
    [OrderCertificateKey.COMPLETED_WORKS_TABLE_TITLE]: 'Completed works',
    [OrderCertificateKey.NAME_WORKS]: 'Title of work',
    [OrderCertificateKey.QUANTITY]: 'Quantity',
    [OrderCertificateKey.PRICE]: 'Price',
    [OrderCertificateKey.SUM]: 'Sum',
    [OrderCertificateKey.UNIT]: 'pcs',
    [OrderCertificateKey.UAH]: 'uah',
    [OrderCertificateKey.TOTAL]: 'Total',
    [OrderCertificateKey.SPARE_PARTS_TABLE_TITLE]: 'Used spare parts (materials) paid for by the customer',
    [OrderCertificateKey.NAME_SPARE_PARTS]: 'Name of spare part',
    [OrderCertificateKey.CODE]: 'Code',
    [OrderCertificateKey.SPARE_PARTS_CLIENT_TABLE_TITLE]: 'Used spare parts (materials) received from the customer',
    [OrderCertificateKey.TOTAL_TABLE_TITLE]: 'Total price',
    [OrderCertificateKey.TOTAL_PRICE_WORKS]: 'Cost of works',
    [OrderCertificateKey.TOTAL_PRICE_SPARE_PARTS]: 'Cost of spare parts',
    [OrderCertificateKey.TOTAL_PRICE]: 'Total to be paid',
    [OrderCertificateKey.FOOTER_TITLE]: 'Notes and recommendations to the client regarding maintenance/repair ' +
      'and operation of vehicles',
    [OrderCertificateKey.VEHICLE_CONFIRM]: 'Транспортний засіб здав',
    [OrderCertificateKey.CONDITIONS_CONFIRM]: 'I agree with the scope and cost of the works. I accepted the vehicle, ' +
      'I have no complaints about the completeness of the manual',
    [OrderCertificateKey.PERFORMER_SIGN_TITLE]: 'From the performer',
    [OrderCertificateKey.CUSTOMER_SIGN_TITLE]: 'From the customer',
    [OrderCertificateKey.FULL_NAME]: 'Name and surname',
    [OrderCertificateKey.SIGNATURE]: 'signature',
    [OrderCertificateKey.DATE]: 'Date',
    [OrderCertificateKey.DOWNLOAD_PDF]: 'Download PDF',
    [OrderCertificateKey.BACK_BUTTON]: 'Back',
  },
  [AppTranslationKey.CLIENT_SPARE_PARTS]: {
    [ClientSparePartsTranslationKey.NOT_ADDED_SPARE_PART_ERROR]: 'Please click "Add" or clear the fields',
    [ClientSparePartsTranslationKey.TITLE]: 'Spare parts from the customer',
    [ClientSparePartsTranslationKey.NAME]: 'Name',
    [ClientSparePartsTranslationKey.COUNT]: 'Count',
    [ClientSparePartsTranslationKey.ADD_SPARE_PART_TITLE]: 'Add',
    [ClientSparePartsTranslationKey.ADDED]: 'Added',
    [ClientSparePartsTranslationKey.NO_SPARE_PARTS]: 'No customer spare parts',
    [ClientSparePartsTranslationKey.CLOSE]: 'Close',
    [ClientSparePartsTranslationKey.SAVE]: 'Save',
    [ClientSparePartsTranslationKey.REQUIRED]: 'This field is required',
    [ClientSparePartsTranslationKey.EMPTY_SPARE_PARTS_LIST_ERROR]:
      'The form cannot be empty. Add at least one spare part',
    [ClientSparePartsTranslationKey.SUCCESS_ADDED_SP]: 'Request spare parts changed successfully.',
  },
  [AppTranslationKey.FEED_BACK_MODAL]: {
    [FeedBackModalKey.TITLE]: 'Feedback, questions, suggestions form',
    [FeedBackModalKey.DEFAULT_PLACEHOLDER]: 'Message content...',
    [FeedBackModalKey.SEND_BUTTON_TITLE]: 'Send',
    [FeedBackModalKey.ERROR_MESSAGE]: 'An error occurred while sending the letter',
    [FeedBackModalKey.SUCCESS_MESSAGE]: 'Thank you for your request! You will receive a reply to your email: {{email}}',
    [FeedBackModalKey.WARNING_FILES_SIZE]: 'Total file size must be less than 25MB.',
    [FeedBackModalKey.MB]: 'MB',
    [FeedBackModalKey.KB]: 'KB',
  },
  [AppTranslationKey.HELP_ABOUT_REQUEST]: {
    [HelpAboutRequestKey.TITLE]: 'Under what statuses is the application considered open?',
    [HelpAboutRequestKey.DESCRIPTION]: 'The system uses a number of statuses to display the current stage of ' +
    'vehicle maintenance. For users who have issued a tariff plan with a limited number of open applications, ' +
    'you should know that the statuses are divided into two categories:',
    [HelpAboutRequestKey.ACTIVE_STATUSES_TITLE]: '(The application is considered open):',
    [HelpAboutRequestKey.NO_ACTIVE_STATUSES_TITLE]: '(The application is considered inactive at the moment or closed):',
    [HelpAboutRequestKey.A_IN_WORK]: 'At work;',
    [HelpAboutRequestKey.A_WAIT_SPARE_PARTS]: 'Waiting for spare parts;',
    [HelpAboutRequestKey.A_IN_WORK_CONTRACTOR]: 'In the work of the contractor;',
    [HelpAboutRequestKey.A_WORKS_FINISHED]: 'Works are completed;',
    [HelpAboutRequestKey.A_WAIT_PAY]: 'Pending payment;',
    [HelpAboutRequestKey.A_WAIT_GIVE_CAR]: 'Waiting for delivery of the car.',
    [HelpAboutRequestKey.NA_NEW]: 'New;',
    [HelpAboutRequestKey.NA_WAIT_AGREEMENT]: 'On agreement;',
    [HelpAboutRequestKey.NA_AGREEMENT]: 'Agreed;',
    [HelpAboutRequestKey.NA_WAIT_RECEIVE_CAR]: 'Waiting for the car to be picked up;',
    [HelpAboutRequestKey.NA_WAIT_WORK]: 'Waiting for employment;',
    [HelpAboutRequestKey.NA_CAR_GAVE]: 'The car is issued;',
    [HelpAboutRequestKey.NA_CLOSE]: 'Closed;',
    [HelpAboutRequestKey.NA_CANCEL]: 'Rejected;',
  },
  [AppTranslationKey.EMPLOYEES_PROFILES]: {
    [EmployeesProfilesKey.TITLE]: 'Profiles of Employees',
    [EmployeesProfilesKey.ADD_BUTTON_TITLE]: 'Add an employee',
    [EmployeesProfilesKey.NO_UNIT_TITLE]: 'You don\'t have any employees :(',
    [EmployeesProfilesKey.NO_UNIT_TEXT]: 'Click the "Add an employee" button to create it',
    [EmployeesProfilesKey.MODAL_TITLE]: 'Employee',
    [EmployeesProfilesKey.FIELDS]: {
      [EmployeesProfilesKey.FIRST_NAME]: 'Name',
      [EmployeesProfilesKey.SECOND_NAME]: 'Surname',
      [EmployeesProfilesKey.PATRONYMIC_NAME]: 'Patronymic',
      [EmployeesProfilesKey.POSITION]: 'Position',
      [EmployeesProfilesKey.REGISTRATION_DATE]: 'Date of employment',
      [EmployeesProfilesKey.STATUS]: 'Status',
      [EmployeesProfilesKey.DISMISSAL_DATE]: 'Dismissal Date',
      [EmployeesProfilesKey.METHOD_OF_SALARY]: 'Method of salary calculation',
      [EmployeesProfilesKey.BIRTHDATE]: 'Birthday',
      [EmployeesProfilesKey.PHONE]: 'Phone',
      [EmployeesProfilesKey.VIBER]: 'Viber',
      [EmployeesProfilesKey.TELEGRAM]: 'Telegram',
      [EmployeesProfilesKey.EMAIL]: 'Email',
      [EmployeesProfilesKey.ADD_INFO]: 'Additional Information',
      [EmployeesProfilesKey.PERCENT_WORKS]: 'Percentage of the cost of the work performed',
      [EmployeesProfilesKey.PERCENT_SPARES]: 'A percentage of the sale of spare parts',
      [EmployeesProfilesKey.HOURLY_RATE]: ' Hourly pay based on the planned standard hours',
      [EmployeesProfilesKey.FIXED_RATE]: 'The amount of the fixed monthly rate',
      [EmployeesProfilesKey.INVALID_PHONE_NUMBER]: 'Invalid phone number',
      [EmployeesProfilesKey.EMAIL_ERROR_TEXT]: 'Please enter a valid e-mail',
      [EmployeesProfilesKey.PERCENT_WORKS_V2]: 'Percentage of the cost of the completed works',
      [EmployeesProfilesKey.PERCENT_SPARES_V2]: 'A percentage of the sale of spare parts',
      [EmployeesProfilesKey.HOURLY_RATE_V2]: 'Hourly pay based on scheduled standard hours for each job',
      [EmployeesProfilesKey.FIXED_RATE_V2]: 'Fixed monthly rate',
      [EmployeesProfilesKey.CONTACTS]: 'Contacts',
      [EmployeesProfilesKey.WORK_PERIOD]: 'Period of work',
    },
    [EmployeesProfilesKey.COLUMNS]: {
      [EmployeesProfilesKey.FULL_NAME]: 'Full name',
      [EmployeesProfilesKey.ADD_INFO]: 'Additional Information',
      [EmployeesProfilesKey.POSITION]: 'Position',
      [EmployeesProfilesKey.PHONE]: 'Phone',
      [EmployeesProfilesKey.STATUS]: 'Status',
    },
    [EmployeesProfilesKey.REQUIRED_FORM_FIELD]: 'This is a required field',
    [EmployeesProfilesKey.SUCCESS_UPDATE_MESSAGE]: 'Employee has been successfully updated',
  },
  [AppTranslationKey.SELECT_COMPONENT]: {
    [SelectComponentKey.SEARCH_SELECT_PLACEHOLDER]: 'Start writing for the selection ...',
    [SelectComponentKey.OTHER_OPTION_NAME]: 'Other...',
  },
  [AppTranslationKey.SALARY]: {
    [SalaryKey.TITLE]: 'Salary',
    [SalaryKey.COLUMNS]: {
      [SalaryKey.FULL_NAME]: 'Full name',
      [SalaryKey.POSITION]: 'Position',
      [SalaryKey.RATE]: 'Rate',
      [SalaryKey.NORMA_HOURS]: 'Norma-hours',
      [SalaryKey.PERCENT_OF_WORK]: '% of work',
      [SalaryKey.PERCENT_OF_SPARE_PARTS]: '% of spare parts',
      [SalaryKey.PRIZE]: 'Prize',
      [SalaryKey.PENALTIES]: 'Penalties',
      [SalaryKey.TOGETHER]: 'Together',
      [SalaryKey.TOTAL]: 'Total',
      [SalaryKey.TOTAL_EMPLOYEES]: 'Total employees',
    },
  },
};
