import { CSmartTable } from '@coreui/react-pro';
import { SorterValue } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey } from 'common/enums/enums';
import { EmployeeTableRow } from 'common/types/types';
import { NoData, ToggleShowDetailsBtn } from 'components/common/common';
import { useAppSelector, useMemo, usePersistentPagination } from 'hooks/hooks';
import React, { FC, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IdCell } from './cells/id-cell';
import { InfoCell } from './cells/info-cell';
import { StatusCell } from './cells/status-cell';
import { getTableColumn, getTableData } from './helpers/helpers';
import { ItemDetails } from './item-details';

type Props = {
  activeCarServiceId: number,
  onToggleRow: (id: number) => void,
  openRows: number[],
};

const EmployeeTable: FC<Props> = ({ activeCarServiceId, onToggleRow, openRows }) => {
  const { t } = useTranslation([AppTranslationKey.EMPLOYEES_PROFILES, AppTranslationKey.COMMON]);
  const employees = useAppSelector(({ employees }) => employees.employees.data);
  const [sorterValue, setSorterValue] = useState<SorterValue>({ column: 'id', state: 'desc' });
  const items = useMemo(() => getTableData(employees, sorterValue), [employees, sorterValue]);
  const { paginationState, handleSizeChange, handlePageChange } = usePersistentPagination(activeCarServiceId);

  const onItemsPerPageChange = (value: number): void => {
    handleSizeChange(value);
    handlePageChange(1);
  };

  return (
    <>
      <CSmartTable
        columns={ getTableColumn() }
        items={ items }
        columnSorter={{ external: true, resetable: false }}
        onSorterChange={ setSorterValue }
        sorterValue={{ column: 'id', state: 'desc' }}
        noItemsLabel={
          <NoData
            title={t('noItemsFoundTitle', { ns: AppTranslationKey.COMMON })}
            message={t('noItemsFoundMsg', { ns: AppTranslationKey.COMMON })}
          />
        }
        itemsPerPageLabel={ t('itemPerPageLabel', { ns: AppTranslationKey.COMMON }) as string }
        tableHeadProps={{ color: 'light' }}
        tableProps={{ striped: true, hover: true }}
        itemsPerPageSelect
        itemsPerPage={ paginationState[activeCarServiceId]?.perPage ?? 10 }
        activePage={ paginationState[activeCarServiceId]?.activePage ?? 1 }
        pagination
        onActivePageChange={handlePageChange}
        onItemsPerPageChange={onItemsPerPageChange}
        scopedColumns={ {
          info: (item: EmployeeTableRow): ReactElement => <InfoCell { ...item } />,
          status: (item: EmployeeTableRow): ReactElement => <StatusCell { ...item } />,
          id: (item: EmployeeTableRow): ReactElement => <IdCell { ...item } carServiceId={ activeCarServiceId } />,
          showDetails: (item: EmployeeTableRow) => (
            <ToggleShowDetailsBtn
              toggleDetails={(): void => onToggleRow(item.id)}
              isOpen={ openRows.includes(item.id) }
            />
          ),
          details: (item) => (
            <ItemDetails
              item={ item as EmployeeTableRow }
              carServiceId={ activeCarServiceId }
              visible={ openRows.includes(item.id) }
            />
          ),
        } }
      />
    </>
  );
};

export { EmployeeTable };
