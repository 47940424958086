enum FeedBackThemesOfQuestionsEnum {
// -Повідомити про технічну проблему (выбрана по умолчанию)
  TECHNICAL_PROBLEM = 'technicalProblem',
// -Пропозиція нової функціональності
  OFFERING_NEW_FUNCTION = 'offeringNewFunctionality',
// -Запропонувати покращення інтерфейсу
  OFFERING_NEW_INTERFACE = 'offeringNewInterface',
// -Залишити відгук
  FEEDBACK = 'feedback',
// -Інший варіант
  OTHER = 'other',
}

export { FeedBackThemesOfQuestionsEnum };
