import { CAlert, CButton, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react-pro';
import { Item } from '@coreui/react-pro/dist/components/smart-table/types';
import { AppTranslationKey } from 'common/enums/enums';
import { usePermissionToastContext } from 'components/common/common';
import { useMemo, useState } from 'hooks/hooks';
import truncate from 'lodash/truncate';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { setCurrentCar } from 'store/car/reducer';

import { useAppDispatch } from '../../../hooks/use-app-dispatch/use-app-dispatch.hook';
import { CarTable } from './car-table';

type Props = {
  isOpen: boolean;
  onClose: () => void,
  onOpenAddCarModal: () => void,
  carServiceId?: number,
};

const ListCarsModalForm: FC<Props> = ({
  isOpen,
  onClose,
  carServiceId,
  onOpenAddCarModal,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(AppTranslationKey.CAR_MODAL);
  const [selectCar, setSelectCar] = useState<Item | null>(null);
  const { onPermissionAction } = usePermissionToastContext();

  const selectedCarText = useMemo(() => {
    if (selectCar) {
      const { carRegNum, carBrandName, carModelName, carInfo } = selectCar;
      const labelCar = truncate(`${carRegNum} ${carBrandName ?? ''} ${carModelName ?? ''} ${carInfo ?? ''}`, { length: 70 });

      return t('selectCarText', { car: labelCar });
    }

    return t('noSelectCarText');
  }, [selectCar, t]);

  const onCheckCar = (items: Item[]): void => {
    setSelectCar(items.length ? items[items.length - 1] : null);
  };

  const onCloseModal = (): void => {
    setSelectCar(null);
    onClose();
  };

  const onSelectCar = (): void => {
    if (selectCar) {
      dispatch(setCurrentCar(selectCar.carId));
      onCloseModal();
    }
  };

  return (
    <CModal
      onClose={onCloseModal}
      visible={isOpen}
      backdrop="static"
      size="lg"
      scrollable
    >
      <CModalHeader>
        <CModalTitle>{t('modalListTitle')}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        <CAlert color="dark">{ selectedCarText }</CAlert>
        <CarTable carServiceId={ carServiceId } onCheckCar={ onCheckCar } selected={ selectCar ? [selectCar] : [] } />
      </CModalBody>
      <CModalFooter className="justify-content-between">
        <CButton onClick={ onPermissionAction(onOpenAddCarModal) }>
          { t('addNewCarBtn') }
        </CButton>
        <CButton onClick={ onSelectCar }>
          { t('selectBtn') }
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export { ListCarsModalForm };
