import { CCardBody, CCardTitle, CFormInput } from '@coreui/react-pro';
import { AppTranslationKey } from 'common/enums/enums';
import { CarServiceProfileFormData } from 'common/types/types';
import { onlyPhoneString } from 'helpers/helpers';
import { useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

type Props = {
  register: UseFormRegister<CarServiceProfileFormData>,
  setValue:  UseFormSetValue<CarServiceProfileFormData>,
  disabled?: boolean,
};

const ContactCard: FC<Props> = ({ register, disabled, setValue }) => {

  const { t } = useTranslation([AppTranslationKey.CAR_SERVICE, AppTranslationKey.COMMON]);
  const [isMainPhoneInvalid, setMainPhoneInvalid] = useState<boolean>(false);
  const [isPhoneInvalid, setPhoneInvalid] = useState<boolean>(false);

  const setMainPhoneValidValue = (value: string): void => {
    if (value.length > 15) {
      setMainPhoneInvalid(true);
      value = value.slice(0, 15);
    } else {
      setMainPhoneInvalid(false);
      value = onlyPhoneString(value);
    }

    setValue('mainContactPhone.contactData', value, { shouldValidate: true });
  };

  const setPhoneValidValue = (value: string): void => {
    if (value.length > 15) {
      setPhoneInvalid(true);
      value = value.slice(0, 15);
    } else {
      setPhoneInvalid(false);
      value = onlyPhoneString(value);
    }

    setValue('contactPhone.contactData', value, { shouldValidate: true });
  };

  return (
    <div className={styles.cardContainer}>
      <CCardTitle className={styles.cardTitle}>{t('contactsCardTitle')}</CCardTitle>
      <CCardBody>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('mainContactPhone.contactData')}
                disabled={disabled}
                type="text"
                label={t('mainPhone')}
                name="mainContactPhone.contactData"
                invalid={isMainPhoneInvalid}
                feedbackInvalid={ t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON }) }
                onChange={(event): void => setMainPhoneValidValue(event.target.value)}
                onBlur={(): void => setMainPhoneInvalid(false)}
              />
            </div>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactPhone.contactData')}
                disabled={disabled}
                type="text"
                label={t('phone')}
                name="contactPhone.contactData"
                invalid={isPhoneInvalid}
                feedbackInvalid={ t('maxPhoneLengthError', { ns: AppTranslationKey.COMMON }) }
                onChange={(event): void => setPhoneValidValue(event.target.value)}
                onBlur={(): void => setPhoneInvalid(false)}
              />
            </div>
          </div>
          <div
            style={{
              marginTop: 15,
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactViber.contactData')}
                disabled={disabled}
                type="text"
                label={t('viber')}
                name="contactViber.contactData"
              />
            </div>
            <div style={{ width: '45%' }}>
              <CFormInput
                {...register('contactTelegram.contactData')}
                disabled={disabled}
                type="text"
                label={t('telegram')}
                name="contactTelegram.contactData"
              />
            </div>
          </div>
          <div style={{ marginTop: 18 }}>
            <CFormInput
              {...register('contactEmail.contactData')}
              disabled={disabled}
              type="text"
              label={t('email')}
              name="contactEmail.contactData"
            />
          </div>
        </div>
      </CCardBody>
    </div>
  );
};

export { ContactCard };
