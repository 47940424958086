import uk from 'date-fns/locale/uk';

const Calendar = {
  sunday: 'Нд',
  monday: 'Пн',
  tuesday: 'Вт',
  wednesday: 'Ср',
  thursday: 'Чт',
  friday: 'Пт',
  saturday: 'Сб',
  ok: 'OK',
  today: 'Сьогодні',
  yesterday: 'Вчора',
  hours: 'Години',
  minutes: 'Хвилини',
  seconds: 'Секунди',
  formattedMonthPattern: 'MM.yyyy',
  formattedDayPattern: 'dd.MM.yyyy',
  dateLocale: uk,
};

export const locale = {
  common: {
    loading: 'Завантаження...',
    emptyMessage: 'Нема даних',
  },
  Plaintext: {
    unfilled: 'Незаповнений',
    notSelected: 'Не вибрано',
    notUploaded: 'Не завантажено',
  },
  Pagination: {
    more: 'Більше',
    prev: 'Попередній',
    next: 'Далі',
    first: 'Перший',
    last: 'Останній',
    limit: '{0} / сторінка',
    total: 'Всього рядків: {0}',
    skip: 'Перейти до {0}',
  },
  Calendar,
  DatePicker: {
    ...Calendar,
  },
  DateRangePicker: {
    ...Calendar,
    last7Days: 'Останні 7 днів',
  },
  Picker: {
    noResultsText: 'Нічого не знайдено',
    placeholder: 'Виберіть',
    searchPlaceholder: 'Пошук',
    checkAll: 'Все',
  },
  InputPicker: {
    newItem: 'Новий пункт',
    createOption: 'Створити опцію "{0}"',
  },
  Uploader: {
    inited: 'Початковий',
    progress: 'Завантаження',
    error: 'Помилка',
    complete: 'Готово',
    emptyFile: 'Порожній',
    upload: 'Завантажити',
  },
  CloseButton: {
    closeLabel: 'Закрити',
  },
  Breadcrumb: {
    expandText: 'Показати шлях',
  },
  Toggle: {
    on: 'Відкрити',
    off: 'Закрити',
  },
};
