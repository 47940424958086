import { format, formatDistance } from 'date-fns';

type FormatDate =
  | 'distance'
  | 'yyyy-MM-dd'
  | 'dd-MM-yyyy'
  | 'dd.MM.yyyy'
  | 'dd MMM yyyy'
  | 'HH:mm'
  | 'HH:mm:ss'
  | 'dd MMM'
  | 'HH:mm, dd.MM'
  | 'kk:mm, dd/MM/yyyy'
  | 'HH:mm dd.MM.yyyy'
  | 'dd.MM.yyyy HH:mm'
  | 'yyyy/MM/dd HH:mm'
  | 'yyyy-MM-dd HH:mm'
  | 'MM.yyyy'
  | 'yyyy-MM-dd HH:mm:ss';

const getFormattedDate = (date: string|Date, formatDate: FormatDate): string => {
  if (date === undefined) return '';

  switch (formatDate) {
  case 'distance': {
    return formatDistance(new Date(date), new Date());
  }
  case 'HH:mm':
  case 'HH:mm:ss':
  case 'dd MMM':
  case 'HH:mm, dd.MM':
  case 'yyyy-MM-dd':
  case 'dd-MM-yyyy':
  case 'dd.MM.yyyy':
  case 'dd MMM yyyy':
  case 'HH:mm dd.MM.yyyy':
  case 'yyyy-MM-dd HH:mm:ss':
  case 'kk:mm, dd/MM/yyyy':
  case 'yyyy/MM/dd HH:mm':
  case 'yyyy-MM-dd HH:mm':
  case 'MM.yyyy':
  case 'dd.MM.yyyy HH:mm': {
    return format(new Date(date), formatDate);
  }
  }
};

export { getFormattedDate };
