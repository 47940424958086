import { CForm, CModalBody } from '@coreui/react-pro';
import { EmployeeDto, EmployeeFormData } from 'common/types/types';
import { parse } from 'date-fns';
import { getFormattedDate } from 'helpers/helpers';
import { useAppDispatch, useEffect, useForm, useState } from 'hooks/hooks';
import React, { FC } from 'react';
import { employeesActions } from 'store/actions';

import {
  AddInfoField,
  BirthdayField,
  DismissalDateField,
  EmailField,
  NameField,
  PatronymicField,
  PhoneField,
  PositionField,
  RegistrationDateField,
  SalaryField,
  StatusField,
  SurnameField,
  TelegramField,
  ViberField,
} from './fields';
import { ModalFooter } from './modal-footer';

type Props = {
  carServiceId: number,
  employeeId?: number,
  data: EmployeeFormData,
  isDisable: boolean,
  salaryDateFrom?: string,
  salaryDateTo?: string,
  onEnable?: () => void,
  onClose: () => void,
};

const EmployeeForm: FC<Props> = ({
  carServiceId,
  employeeId,
  data,
  isDisable,
  onEnable,
  onClose,
  salaryDateFrom,
  salaryDateTo,
}) => {
  const dispatch = useAppDispatch();
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<EmployeeFormData>({
    defaultValues: data,
  });

  const statusWatch = +watch('statusId');
  const infoWatch = watch('employeeInfo') || '';

  const [showDismissalDate, setShowDismissalDate] = useState( statusWatch === 5 );

  useEffect(() => {
    setShowDismissalDate(statusWatch === 5);
  }, [statusWatch]);

  useEffect(() => {
    reset(data);
  }, [data]);

  const onSubmit = (data: EmployeeFormData): void => {
    const payload: EmployeeDto = {
      firstName: data.firstName,
      secondName: data.secondName,
      patronymicName: data.patronymicName,
      birthDate: data.birthDate
        ? getFormattedDate(parse(data.birthDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
        : undefined,
      registrationDate: getFormattedDate(parse(data.registrationDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd'),
      dismissalDate: showDismissalDate
        ? getFormattedDate(parse(data.dismissalDate, 'dd.MM.yyyy', new Date()), 'yyyy-MM-dd')
        : undefined,
      employeeStatusId: +data.statusId,
      employeePositionId: +data.positionId,
      employeeInfo: data.employeeInfo,
      employeeContactList: [
        { contactTypeId: 1, contactData: data.phone.value, contactId: data.phone.contactId },
        { contactTypeId: 2, contactData: data.email.value, contactId: data.email.contactId },
        { contactTypeId: 4, contactData: data.telegram.value, contactId: data.telegram.contactId },
        { contactTypeId: 5, contactData: data.viber.value, contactId: data.viber.contactId },
      ],
      employeeSalaryDataList: data.salaryTypes.map((item) => ({
        rowId: item.rowId,
        salaryTypeId: item.salaryTypeId,
        salaryTypeValue: item.salaryTypeValue,
      })),
    };

    if (employeeId) {
      dispatch(employeesActions.updateEmployee({ ...payload, employeeId }))
        .then(() => dispatch(employeesActions.getEmployees({ carServiceId, salaryDateFrom, salaryDateTo })))
        .then(() => onClose());
    } else {
      dispatch(employeesActions.createEmployee({ ...payload, carServiceId })).then(() => onClose());
    }
  };

  return (
    <CForm onSubmit={ handleSubmit(onSubmit) } className="form-scroll">
      <CModalBody>
        <NameField register={ register } errors={ errors } isDisable={ isDisable } />
        <SurnameField register={ register } errors={ errors } isDisable={ isDisable } />
        <PatronymicField register={ register } isDisable={ isDisable } />
        <PositionField register={ register } errors={ errors } isDisable={ isDisable } />
        <RegistrationDateField control={ control } isDisable={ isDisable } />
        <StatusField register={ register } errors={ errors } isDisable={ isDisable } />
        { showDismissalDate && <DismissalDateField control={ control } isDisable={ isDisable } /> }
        <SalaryField register={ register } control={ control } errors={ errors } isDisable={ isDisable } />
        <BirthdayField control={ control } isDisable={ isDisable } />
        <PhoneField control={ control } isDisable={ isDisable } />
        <TelegramField register={ register } isDisable={ isDisable } />
        <ViberField control={ control } isDisable={ isDisable } />
        <EmailField register={ register } errors={ errors } isDisable={ isDisable } />
        <AddInfoField register={ register } value={ infoWatch } isDisable={ isDisable } />
      </CModalBody>
      <ModalFooter onClose={ onClose } isDisable={ isDisable } onEnable={ onEnable } />
    </CForm>
  );
};

export { EmployeeForm };
