import background from 'assets/img/auth-bg.jpg';
import { AppRoute, AppTranslationKey, ENV, PersonalRoute } from 'common/enums/enums';
import { UserSignInRequestDto, UserSignUpRequestDto } from 'common/types/types';
import { ExternalLangSelector, Icon } from 'components/common/common';
import { toBase64 } from 'helpers/helpers';
import { useAppDispatch, useAppSelector, useEffect, useLocation, useNavigate, useStatistics } from 'hooks/hooks';
import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { authActions } from 'store/actions';

import { RecoveryPassword, SignInForm, SignUpForm } from './components/components';
import styles from './styles.module.scss';

const Auth: FC = () => {

  const location = useLocation();
  const { t } = useTranslation(AppTranslationKey.COMMON);
  const fromPage = location?.state?.from?.pathname
    || `${AppRoute.PERSONAL}${PersonalRoute.ROOT}${PersonalRoute.DASHBOARDS}`;
  const { currentUser }  = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigation = useNavigate();
  const hasUser = Boolean(currentUser);
  const { sendStat } = useStatistics();

  useEffect(() => {
    sendStat();
  }, [ location.pathname, location.search, sendStat ]);

  const handleSignInSubmit = (payload: UserSignInRequestDto): void => {
    const credential: UserSignInRequestDto = {
      username: toBase64(payload.username),
      password: toBase64(payload.password),
    };

    dispatch(authActions.signIn(credential));
  };

  const handleSignUpSubmit = ( { locale, password, username, name, phone }: UserSignUpRequestDto): void => {
    dispatch(authActions.signUp({ username, name, password, locale, phone }))
      .unwrap()
      .then(() => navigation((AppRoute.SIGN_IN)));
  };

  const getScreen = (screen: string): ReactElement | null => {
    switch (screen) {

    case AppRoute.SIGN_UP: {
      return <SignUpForm onSubmit={handleSignUpSubmit}/>;
    }

    case AppRoute.SIGN_IN: {
      return <SignInForm onSubmit={handleSignInSubmit}/>;
    }

    case AppRoute.RECOVERY_PASSWORD: {
      return <RecoveryPassword />;
    }
    }

    return <SignInForm onSubmit={handleSignInSubmit} />;
  };

  if(hasUser) {
    return <Navigate to={fromPage} />;
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className={styles.actionContainer}>
        <Tooltip
          id="telegram_logo"
          border="1px solid #d6d8d9"
          content={t('tgLogoTip') ?? 'Telegram support'}
          place="bottom"
          opacity={0.75}
          className={styles.tip}
        />
        <div
          className={styles.iconContainer}
          data-tooltip-id="telegram_logo"
          onClick={(): void => {
            window.open(ENV.TBOT_URL, '_blank');
          }}
        >
          <Icon name={'telegramLogo'} />
        </div>
        <ExternalLangSelector />
      </div>
      {getScreen(location.pathname)}
    </div>
  );
};

export { Auth };
