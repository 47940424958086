import cn from 'classnames';
import { AppTranslationKey, SalaryKey } from 'common/enums/enums';
import { useAppSelector } from 'hooks/hooks';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const Footer: FC = () => {
  const { t } = useTranslation([AppTranslationKey.SALARY, AppTranslationKey.COMMON]);
  const { employees } = useAppSelector(({ employees }) => employees);
  const {
    sumPenaltyAll,
    sumFixedRateAll,
    sumHourlyRateAll,
    sumPercentWorksAll,
    sumPercentSparesAll,
    sumSalaryAll,
    sumBonusAll,
    data,
  } = employees;

  return (
    <div className={ styles.footerContainer }>
      <div className={ styles.labelContainer }>
        <span>{ `${ t(`${SalaryKey.COLUMNS}.${SalaryKey.TOTAL_EMPLOYEES}`) } - ${ data.length }` }</span>
        <span>{ `${ t(`${SalaryKey.COLUMNS}.${SalaryKey.TOTAL}`) }:` }</span>
      </div>
      <div className={ cn(styles.cellValue, styles.rate) }>
        { `${ sumFixedRateAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON }) }` }
      </div>
      <div className={ cn(styles.cellValue, styles.hourlyRate) }>
        { `${ sumHourlyRateAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON }) }` }
      </div>
      <div className={ cn(styles.cellValue, styles.percentWorks) }>
        { `${ sumPercentWorksAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON }) }` }
      </div>
      <div className={ cn(styles.cellValue, styles.percentSpares) }>
        { `${ sumPercentSparesAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON }) }` }
      </div>
      <div className={ cn(styles.cellValue, styles.bonus) }>
        { `${ sumBonusAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON }) }` }
      </div>
      <div className={ cn(styles.cellValue, styles.penalty) }>
        { `${ sumPenaltyAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON }) }` }
      </div>
      <div className={ cn(styles.cellValue, styles.salaryAll) }>
        { `${ sumSalaryAll || 0 } ${ t('uah', { ns: AppTranslationKey.COMMON })}` }
      </div>
    </div>
  );
};

export { Footer };
