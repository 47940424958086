import { cibTelegramPlane, cibViber, cilEnvelopeClosed, cilPhone } from '@coreui/icons';

import styles from './style.module.scss';

enum TypeContactField {
  VIBER = 'viber',
  TELEGRAM = 'telegram',
  EMAIL = 'email',
  PHONE = 'phone',
}

const config = {
  [TypeContactField.PHONE]: {
    icon: cilPhone,
    label: 'Phone',
    className: styles.phone,
  },
  [TypeContactField.VIBER]: {
    icon: cibViber,
    label: 'Viber',
    className: styles.viber,
  },
  [TypeContactField.TELEGRAM]: {
    icon: cibTelegramPlane,
    label: 'Telegram',
    className: styles.telegram,
  },
  [TypeContactField.EMAIL]: {
    icon: cilEnvelopeClosed,
    label: 'Email',
    className: styles.email,
  },
};

export { config, TypeContactField };
