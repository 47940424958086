import { AppTranslationKey, ENV } from 'common/enums/enums';
import { Icon } from 'components/common/icon/icon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

import styles from './styles.module.scss';

const TelegramButton: FC = () => {

  const { t } = useTranslation(AppTranslationKey.COMMON);

  return (
    <>
      <Tooltip
        id="telegram_logo"
        content={t('tgLogoTip') ?? 'Telegram support'}
        place="bottom-end"
        style={{ background: '#383838', color: '#ffffff' }}
      />
      <div
        className={styles.iconContainer}
        data-tooltip-id="telegram_logo"
        onClick={(): void => {
          window.open(ENV.TBOT_URL, '_blank');
        }}
      >
        <Icon name={'telegramLogo'} />
      </div>
    </>
  );
};

export { TelegramButton };
