import { AppTranslationKey , SalaryKey } from 'common/enums/enums';
import { TableColumn } from 'common/types/types';
import { t as tt } from 'i18next';

const translate = (field: string): string => {
  return tt(`${ AppTranslationKey.SALARY }:${SalaryKey.COLUMNS}.${ field }`);
};

const getTableColumn = (): TableColumn[] => [
  {
    key: 'id',
    label: '#',
    sorter: true,
    filter: false,
    _style: { width: '5%' },
  },
  {
    key: 'fullName',
    label: translate(SalaryKey.FULL_NAME),
    sorter: true,
    filter: false,
    _style: { width: '16%' },
  },
  {
    key: 'position',
    label: translate(SalaryKey.POSITION),
    sorter: true,
    filter: false,
    _style: { width: '10%' },
  },
  {
    key: 'rate',
    label: translate(SalaryKey.RATE),
    sorter: true,
    filter: false,
    _style: { width: '9%' },
  },
  {
    key: 'hourlyRate',
    label: translate(SalaryKey.NORMA_HOURS),
    sorter: true,
    filter: false,
    _style: { width: '12%' },
  },
  {
    key: 'percentWorks',
    label: translate(SalaryKey.PERCENT_OF_WORK),
    sorter: true,
    filter: false,
    _style: { width: '10%' },
  },
  {
    key: 'percentSpares',
    label: translate(SalaryKey.PERCENT_OF_SPARE_PARTS),
    sorter: true,
    filter: false,
    _style: { width: '12%' },
  },
  {
    key: 'bonus',
    label: translate(SalaryKey.PRIZE),
    sorter: true,
    filter: false,
    _style: { width: '9%' },
  },
  {
    key: 'penalty',
    label: translate(SalaryKey.PENALTIES),
    sorter: true,
    filter: false,
    _style: { width: '9%' },
  },
  {
    key: 'salaryAll',
    label: translate(SalaryKey.TOGETHER),
    sorter: true,
    filter: false,
    _style: { width: '8%' },
  },
];

export { getTableColumn };
